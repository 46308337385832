import CardTarif from './CardTarif/CardTarif'
import { api } from '@/mixins/api'
import PrivatSectorTvTariffs from '../Tariffs/PrivateSectorTvTariffs.vue'

export default {
    name: 'tariffs',
    components: {
        CardTarif,
        PrivatSectorTvTariffs
    },
    data() {
        return {
            regions: [
                'Запорожье',
                'Балабино',
                'Кушугум',
                'Наталовка',
                'Новоалександровка',
                'Новоеленовка',
                'Лежино',
                'Григорьевка',
                'Веселянка',
            ],
            regionsAppartament: ['Запорожье', 'Наталовка', 'Новоалександровка'],
            city: 'Запорожье',
            type: 'appartament',
            tariffs: [],
            tarifHouse: 1,
            tarifFlat: 2,
            tvTarifs: 3,
            tvTariffs: []
        }
    },
    methods: {
        toggleAppartament() {
            this.type = 'appartament'
            this.getTariffs(this.tarifFlat)
            this.checktype()
        },
        togglePrivat() {
            this.type = 'privat'
            this.getTariffs(this.tarifHouse)
            this.checktype()
        },
        async getTariffs(param) {
            try {
                const response = (await api.get(`/tariffs/${param}/`)).data
                this.tariffs = response
            } catch (error) {
                console.log(`error`, error)
            }
        },
        async getTvTariffs(param) {
            try {
                const response = (await api.get(`/tariffs/${param}/`)).data
                this.tvTariffs = response
            } catch (error) {
                console.log(`error`, error)
            }
        },
        checktype() {
            if (this.type === 'privat') {
                this.getTariffs(this.tarifHouse)
            }
            if (this.type === 'appartament') {
                this.getTariffs(this.tarifFlat)
            }
        },
    },
    async mounted() {
        console.log(`tut`)
        await this.getTariffs(this.tarifFlat)
        await this.getTvTariffs(this.tvTarifs)

    },
}
