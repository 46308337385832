<template>
  <div class="proposal">
    <div class="container">
      <div class="proposal-info">
        <h1>Получи 1+3+1</h1>
        <p>Учавствуй в акции, оставь заявку на подключение, плати за один месяц, а получи 4 в подарок.</p>
        <p>Скажи кодовое слово “Подарок”</p>
        <ul>
          <li>
            <a href="tel:0507074114">(050) 707 41 14</a>
          </li>
          <li>
            <a href="tel:0687074114">(068) 707 41 14</a>
          </li>
          <li>
            <a href="tel:0737074114">(073) 707 41 14</a>
          </li>
          <li>
            <a href="tel:0800304114">0 800 30 41 14</a>
          </li>
        </ul>
      </div>
      <Form />
    </div>
  </div>
</template>

<script>
  import Form from '@/components/Form/Form'

  export default {
    name: 'proposal',
    components: {
      Form
    }
  }
</script>