import { api } from '@/mixins/api'
import { required } from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            city: 'Запорожье',
            address: '',
            house: '',
            flat: '',
            phone: '',
            isLoading: false,
        }
    },
    validations: {
        address: {
            required,
        },
        phone: {
            required,
        },
    },
    methods: {
        async sendForm() {
            this.isLoading = true
            event.preventDefault()
            let data = {
                address: this.city + ',' + this.address,
                house: this.house,
                flat: Number(this.flat),
                phone: this.phone,
            }
            try {
                await api.post('/connect_request/', data)
            } catch (error) {
                console.log(`error`, error)
            } finally {
                this.city = 'Запорожье'
                this.address = ''
                this.house = ''
                this.flat = ''
                this.phone = ''
                this.isLoading = false
            }
        },
        checkFields() {
            if (this.$v.$invalid) {
                this.$v.$touch()
            } else this.sendForm()
        },
    },
}
