<template>
  <div id="app">
    <Header />
    <Proposal />
    <Tariffs />
    <PreFooter />
    <Questions />
    <Footer />
  </div>
</template>

<script>
import Header from './components/Header/Header'
import Proposal from './components/Proposal/Proposal'
import Tariffs from './components/Tariffs/Tariffs.vue'
import PreFooter from './components/PreFooter/PreFooter'
import Questions from './components/Questions/Questions'
import Footer from './components/Footer/Footer'

export default {
  name: 'App',
  components: {
    Header,
    Proposal,
    Tariffs,
    PreFooter,
    Questions,
    Footer
  }
}
</script>
