<template>
    <div class="questions">
        <div class="container">
            <b>Ответы на часто задаваемые вопросы</b>
            <!-- <p>Исходя из структуры пирамиды Маслоу, аномия выбирает стресс. Мониторинг активности не так уж очевиден.</p> -->
            <ul>
                <li
                    v-for="(item, key) in questionsAnswers"
                    :key="key"
                    :class="{ active: activeQuestion === item.id }"
                    @click="onClickQuestion(item.id)"
                >
                    <div class="questions-title">
                        <span>{{ item.question }}</span>
                        <img src="@/assets/img/icons/i_arrow.svg" alt="" />
                    </div>
                    <div class="questions-answer">
                        <p>
                            {{ item.answer }}
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'questions',
    data() {
        return {
            activeQuestion: null,
            questionsAnswers: [
                {
                    id: 1,
                    question:
                        'Я проживаю в частном секторе города Запорожья. Я могу подключить телевидение и проводной интернет в частный дом?',
                    answer: `Да, конечно. Наши услуги доступны и для многоквартирных домов и для частных домов, при наличии технической возможности подключения услуг. 
                            Если территориально Ваш дом в пределах покрытия оптоволокна, подайте заявку на подключения проводного интернета и телевидения.`,
                },
                {
                    id: 2,
                    question: 'Сколько стоит провести оптоволокно в квартиру или частный дом?',
                    answer: `Мы подключаем проводной интернет и цифровое IP - телевидение абсолютно бесплатно. Акционное предложение действует на все тарифные планы без ограничений по скорости и по трафику.`,
                },
                {
                    id: 3,
                    question: 'Как проверить техническую возможность подключения интернет услуг?',
                    answer: ` Все очень просто! Отправьте заявку на проверку зоны покрытия DiaNet любым удобным вам
                            способом: по номеру телефона, на сайте, через мессенджер. Специалисты техподдержки в течении
                            нескольких минут свяжутся с Вами.`,
                },
                {
                    id: 4,
                    question: 'Я – абонент Укртелеком. Могу ли подключить кабельный интернет и телевидения от ДиаНет?',
                    answer: `Да, конечно, вы можете перейти к интернет провайдеру DiaNet, расторгнув договор с телеком
                            оператором Укртелеком. Более того, мы консультативно поддерживаем наших абонентов на всех
                            этапах перехода: от возврата укртелекомовского роутера и расторжения договора до подключения
                            проводного интернета и цифрового ТВ от DiaNet.`,
                },
                {
                    id: 5,
                    question: 'Есть ли у меня ограничения по входящему и исходящему трафику?',
                    answer: ` Нет, никаких ограничений мы не накладываем. Наш «безлимит» свободен от лимитов и не
                            ограничивает объем данных, которые вы можете отправлять или получать.`,
                },
            ],
        }
    },
    methods: {
        onClickQuestion(id) {
            if (this.activeQuestion !== id) {
                return (this.activeQuestion = id)
            }
            this.activeQuestion = null
        },
    },
}
</script>
