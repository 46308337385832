<template>
    <div class="tarif-chanaleTV">
        <h2>Дополняйте интернет телевиденьем</h2>
        <div class="tarif-chanaleTV-wrapper">
            <div class="tarif-chanaleTV-wrapper-card" v-for="(item, key) in items" :key="key">
                <div>
                    <div class="tarif-chanaleTV-wrapper-card-title">{{ item.name }}</div>
                    <div class="tarif-chanaleTV-wrapper-card-countChanale">
                        <div>
                            <strong>{{ item.count_channels }}</strong> {{ check(item.count_channels) }}
                        </div>
                    </div>
                    <div class="tarif-list__line">
                        <span>Бонусы:</span>
                        <i></i>
                    </div>
                    <div class="tarif-chanaleTV-wrapper-card-wrapper">
                        <div
                            class="tarif-chanaleTV-wrapper-card-wrapper-bonus"
                            v-for="(bonus, key) in item.bonuses_in_tariff"
                            :key="key"
                        >
                            <i v-if="bonus.is_active" class="icon-done"></i>
                            <i v-else class="icon-none"></i>
                            <span v-if="checkNameBonus(bonus.bonuses_name)"
                                ><strong>{{ bonus.bonuses_name.slice(0, 7) }}</strong>
                                {{ bonus.bonuses_name.slice(7) }}
                            </span>
                            <span v-else> {{ bonus.bonuses_name }} </span>
                        </div>
                        <!-- <div class="tarif-chanaleTV-wrapper-card-wrapper-bonus">
                            <i class="icon-done"></i>
                            <i class="icon-none"></i>
                            <span><strong>-44 грн</strong> на інтернет</span>
                        </div> -->
                    </div>
                    <div class="tarif-list__line">
                        <i></i>
                    </div>
                    <div class="tarif-chanaleTV-wrapper-card-price">
                        <strong>{{ item.price }}</strong> грн./міс
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['items'],
    methods: {
        check(num) {
            let n = Math.abs(num)
            n %= 100
            n %= 10
            if ((n >= 5 && n <= 20) || n === 0) {
                return 'каналов'
            }
            if (n === 1) {
                return 'канал'
            }
            if (n >= 2 && n <= 4) {
                return 'канала'
            }
        },
        checkNameBonus(str) {
            console.log(`str`, str)
            if (str.charAt(0) === '-') {
                return true
            } else return false
        },
    },
}
</script>
