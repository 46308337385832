<template>
    <div class="tarif-list">
        <div v-for="(value, id) in tariffs" :key="id">
            <div class="tarif-list__head">
                <div>
                    <span class="tarif-list__head--icon"></span>

                    <div>
                        <b>{{ value.name }}</b>
                        <span>{{ value.title }}</span>
                    </div>
                </div>
                <div>
                    <span
                        ><b>{{ value.price }}</b
                        >грн./мес</span
                    >
                    <a href="#form">Подключить</a>
                </div>
            </div>
            <div class="tarif-list__line">
                <span>ИНТЕРНЕТ:</span>
                <i></i>
            </div>
            <div class="tarif-list__body">
                <span
                    ><b>{{ value.speed }}</b
                    >Мб/с</span
                >
                <div class="tarif-list__line">
                    <span>ТЕЛЕВИДЕНИЕ:</span>
                    <i></i>
                </div>
                <div class="tarif-list__body--chanels">
                    <div>
                        <b>{{ value.channels }}</b>
                        <span>ТВ-каналы</span>
                    </div>
                    <a href="https://dianet.ua/dianet-tv#channels" target="_blank">Список<i class="icon-arrow_right"></i></a>
                </div>
            </div>
            <div class="tarif-list__footer">
                <div class="tarif-list__line">
                    <span>Бонуси:</span>
                    <i></i>
                </div>
                <div v-for="(e, key) in value.bonuses_in_tariff" :key="key" class="tarif-list__advantage">
                    <i v-if="e.is_active" class="icon-done"></i>
                    <i v-else class="icon-none"></i>
                    <span>{{ e.bonuses_name }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['tariffs'],
}
</script>
