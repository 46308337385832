/**
* Global Functions
* */

class API {
  mainUrl = process.env.VUE_APP_URL

  fetch(url, options, body) {
    const token = localStorage.getItem('Token')

    if (!options.headers) {
      options.headers = {}
    }
    options.headers.Accept = 'application/json'
    options.headers['Content-Type'] = 'application/json'
    if (token && url !== 'authorization/mail/login/') {
      options.headers.Authorization = `Token ${token}`
    }
    if (body) {
      options.body = JSON.stringify(body)
    }

    const response = fetchRequest(this.mainUrl + url, options)
    return response
  }

  async fetchPhoto(url, options, formData) {
    const token = localStorage.getItem('Token')

    if (!options.headers) {
      options.headers = {}
    }

    options.headers.Authorization = `Token ${token}`
    options.body = formData

    if (options.method === 'GET') {
      options.headers['Access-Control-Request-Headers'] = '*'
      return fetch(this.mainUrl + url, options)
        .then(response => {
          switch (true) {
            case response.status >= 200 && response.status <= 299:
            case response.status === 400:
              return response.blob().then(images => {
                return URL.createObjectURL(images)
              })
          }
        })
    } else {
      return fetch(this.mainUrl + url, options)
        .then(response => {
          switch (true) {
            case response.status >= 200 && response.status <= 299:
            case response.status === 400:
              return response.json().then(data => {
                return { data, code: response.status }
              })
            case response.status === 401:
              if (window.location.pathname !== '/login' &&
                window.location.pathname !== '/login-phone' &&
                window.location.pathname !== '/processing-personal-data' &&
                window.location.pathname !== '/404' &&
                (window.location.pathname.search('/registration') !== 1 &&
                  window.location.pathname.search('/confirmation') !== 0)) {
                localStorage.removeItem('Token')
                window.location = '/login-phone'
              }
              break
            case response.status === 404:
              return { status: 'not found' }
            case response.status === 500:
              return { status: 'server error' }
            case response.status === 502:
              return { status: 'bad gateway' }
          }
        })
        .catch((error) => {
          console.error(error)
          if ((window.location.pathname !== '/login' &&
            window.location.pathname !== '/login-phone' &&
            window.location.pathname !== '/processing-personal-data' &&
            window.location.pathname !== '/404' &&
            (window.location.pathname.search('/registration') !== 1 &&
              window.location.pathname.search('/confirmation') !== 0)) &&
            error.status === 401) {
            localStorage.removeItem('token')
            window.location = '/login-phone'
          }
        })
    }


  }

  async fetchDoc(url, options, body) {
    const token = localStorage.getItem('Token')

    if (!options.headers) {
      options.headers = {}
    }
    if (token) {
      options.headers.Authorization = `Token ${token}`
    }
    if (body) {
      options.body = body
    }

    return fetch(this.mainUrl + url, options)
      .then(response => {
        switch (true) {
          case response.status >= 200 && response.status <= 299:
          case response.status === 400:
            return response.blob()
        }
      })
  }

  get(url) {
    const options = { method: 'GET' }
    return this.fetch(url, options)
  }

  post(url, body, headers = null) {
    const options = {
      method: 'POST',
      headers
    };
    return this.fetch(url, options, body)
  }

  put(url, body) {
    const options = { method: 'PUT' }
    return this.fetch(url, options, body)
  }

  delete(url, body) {
    const options = { method: 'DELETE' }
    return this.fetch(url, options, body)
  }

  patch(url, body) {
    const options = { method: 'PATCH' }
    return this.fetch(url, options, body)
  }

  getPhoto(url) {
    const options = {
      method: 'GET'
    };
    return this.fetchPhoto(url, options)
  }

  postPhoto(url, formData) {
    const options = {
      method: 'POST'
    };
    return this.fetchPhoto(url, options, formData)
  }

  getDoc(url) {
    const options = {
      method: 'GET'
    };
    return this.fetchDoc(url, options)
  }
}

export const fetchRequest = (url, options) => {
  return fetch(url, options)
    .then(response => {
      switch (true) {
        case response.status === 101:
        case response.status === 204:
        case response.status === 205:
          return { status: 'deleted' }
        case response.status === 304:
          return { status: 'ok' }
        case response.status >= 200 && response.status <= 299:
        case response.status === 400:
          return response.json().then(data => {
            return { data, code: response.status }
          })
        case response.status === 401:
          if (window.location.pathname !== '/login' &&
            window.location.pathname !== '/login-phone' &&
            window.location.pathname !== '/processing-personal-data' &&
            window.location.pathname !== '/404' &&
            (window.location.pathname.search('/registration') !== 0 &&
              window.location.pathname.search('/confirmation') !== 0)) {
            console.log(window.location.pathname.search('/registration'))
            localStorage.removeItem('Token')
            window.location = '/login-phone'
          }
          break
        case response.status === 404:
          return { status: 'not found' }
        case response.status === 500:
          return { status: 'server error' }
        case response.status === 502:
          return { status: 'bad gateway' }
      }
    })
    .catch((error) => {
      if (window.location.pathname !== '/login' &&
        window.location.pathname !== '/login-phone' &&
        window.location.pathname !== '/processing-personal-data' &&
        window.location.pathname !== '/404' &&
        (window.location.pathname.search('/registration') !== 0 &&
          window.location.pathname.search('/confirmation') !== 0) &&
        error.status === 401) {
        localStorage.removeItem('token')
        window.location = '/login-phone'
      }
    })
}

export const api = new API();
