import Vue from 'vue'
import App from './App.vue'
import Multiselect from 'vue-multiselect'
import Vuelidate from 'vuelidate'
import '@/assets/sass/main.sass'

Vue.config.productionTip = false
Vue.component('multiselect', Multiselect)
Vue.use(Vuelidate)

new Vue({
  render: h => h(App),
}).$mount('#app')
